import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useMaterialRequestsList() {
  const toast = useToast()
  const refTransferListTable = ref(null)
  const refTransferListTableNA = ref(null) // Kept
  const refTransferListTableRejected = ref(null) // Added
  const refTransferListTableFinished = ref(null)
  const tableColumns = [
    { key: 'transfer_number', sortable: true },
    { key: 'transfer_branch_sender', sortable: true },
    { key: 'transfer_branch_receiver', sortable: true },
    { key: 'transfer_notes', sortable: true },
    { key: 'transfer_created_time', sortable: true },
    { key: 'Action', sortable: true },
  ]
  const perPage = ref(10)
  const perPageNA = ref(10) // Kept
  const perPageRejected = ref(10) // Added
  const perPageFinished = ref(10)
  const totalTransfer = ref(0)
  const totalTransferNA = ref(0) // Kept
  const totalTransferRejected = ref(0) // Added
  const totalTransferFinished = ref(0)
  const currentPage = ref(1)
  const currentPageNA = ref(1) // Kept
  const currentPageRejected = ref(1) // Added
  const currentPageFinished = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const searchQueryNA = ref('') // Kept
  const searchQueryRejected = ref('') // Added
  const searchQueryFinished = ref('')
  const sortBy = ref('transfer_number')
  const sortByNA = ref('transfer_number') // Kept
  const sortByRejected = ref('transfer_number') // Added
  const sortByFinished = ref('transfer_number')
  const isSortDirDesc = ref(true)
  const isSortDirDescNA = ref(true) // Kept
  const isSortDirDescRejected = ref(true) // Added
  const isSortDirDescFinished = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTransferListTable.value ? refTransferListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransfer.value,
    }
  })
  const dataMetaNA = computed(() => { // Kept
    const localItemsCount = refTransferListTableNA.value ? refTransferListTableNA.value.localItems.length : 0
    return {
      from: perPageNA.value * (currentPageNA.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageNA.value * (currentPageNA.value - 1) + localItemsCount,
      of: totalTransferNA.value,
    }
  })
  const dataMetaRejected = computed(() => { // Added
    const localItemsCount = refTransferListTableRejected.value ? refTransferListTableRejected.value.localItems.length : 0
    return {
      from: perPageRejected.value * (currentPageRejected.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageRejected.value * (currentPageRejected.value - 1) + localItemsCount,
      of: totalTransferRejected.value,
    }
  })
  const dataMetaFinished = computed(() => {
    const localItemsCount = refTransferListTableFinished.value ? refTransferListTableFinished.value.localItems.length : 0
    return {
      from: perPageFinished.value * (currentPageFinished.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageFinished.value * (currentPageFinished.value - 1) + localItemsCount,
      of: totalTransferFinished.value,
    }
  })

  const refetchData = () => {
    refTransferListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, sortBy, currentPageFinished, perPageFinished, searchQueryFinished, sortByFinished, currentPageNA, perPageNA, searchQueryNA, sortByNA, currentPageRejected, perPageRejected, searchQueryRejected, sortByRejected, roleFilter, statusFilter, isSortDirDesc, isSortDirDescFinished, isSortDirDescNA, isSortDirDescRejected], () => {
    refetchData()
  })

  const fetchTransfers = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_TRANSFERS}`, {
        headers,
        params: {
          search: searchQuery.value,
          order_by: sortBy.value,
          order_sort: isSortDirDesc.value ? 'DESC' : 'ASC',
          is_finished: false,
        },
      })
      .then(response => {
        totalTransferFinished.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchTransfersNA = (ctx, callback) => { // Kept
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_TRANSFERS}`, {
        headers,
        params: {
          search: searchQueryNA.value,
          order_by: sortByNA.value,
          order_sort: isSortDirDescNA.value ? 'DESC' : 'ASC',
          needs_approval: true,
        },
      })
      .then(response => {
        totalTransferNA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchTransfersRejected = (ctx, callback) => { // Added
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_TRANSFERS}`, {
        headers,
        params: {
          search: searchQueryRejected.value,
          order_by: sortByRejected.value,
          order_sort: isSortDirDescRejected.value ? 'DESC' : 'ASC',
          is_rejected: true,
        },
      })
      .then(response => {
        totalTransferRejected.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchTransfersFinished = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_TRANSFERS}`, {
        headers,
        params: {
          search: searchQueryFinished.value,
          order_by: sortByFinished.value,
          order_sort: isSortDirDescFinished.value ? 'DESC' : 'ASC',
          is_finished: true,
        },
      })
      .then(response => {
        totalTransferFinished.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchTransfers,
    fetchTransfersNA, // Kept
    fetchTransfersRejected, // Added
    fetchTransfersFinished,
    tableColumns,
    perPage,
    currentPage,
    totalTransfer,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTransferListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    sortByFinished,
    isSortDirDescFinished,
    dataMetaFinished,
    currentPageFinished,
    totalTransferFinished,
    perPageFinished,
    searchQueryFinished,
    sortByNA, // Kept
    isSortDirDescNA, // Kept
    totalTransferNA, // Kept
    dataMetaNA, // Kept
    currentPageNA, // Kept
    perPageNA, // Kept
    searchQueryNA, // Kept
    sortByRejected, // Added
    isSortDirDescRejected, // Added
    totalTransferRejected, // Added
    dataMetaRejected, // Added
    currentPageRejected, // Added
    perPageRejected, // Added
    searchQueryRejected, // Added
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
