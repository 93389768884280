<template>
  <div>
    <!-- Modal Rejects -->
<b-modal
  id="modal-reject-confirmation"
  v-model="modalReject"
  cancel-variant="outline-secondary"
  hide-footer
  centered
  title="Reject Confirmation"
>
  <b-form>
    <div>
      <p>Are you sure you want to reject this data?</p>
      <b-row class="mt-3">
        <b-col cols="6" class="text-right">
          <b-button
            variant="secondary"
            @click="modalReject = false"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col cols="6" class="text-left">
          <b-button
            variant="danger"
            @click="confirmReject()"
          >
            Reject
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-form>
</b-modal>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-tabs>
        <b-tab title="On Going">
          <div class="m-2">

<!-- Table Top -->
<b-row>

  <!-- Per Page -->
  <b-col
    cols="12"
    md="6"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
  >
    <label>Show</label>
    <v-select
      v-model="perPage"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
    />
    <label>entries</label>
  </b-col>
  <b-col
    cols="12"
    md="6"
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-form-input
        v-model="searchQuery"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
      <b-button
        v-if="createShow"
        variant="primary"
        @click="addNew()"
      >
        <span class="text-nowrap">Add Transfer</span>
      </b-button>
    </div>
  </b-col>
</b-row>
</div>
      <b-table
        ref="refTransferListTable"
        class="position-relative"
        :items="fetchTransfers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Number -->
        <template #cell(transfer_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.transfer_number }}</span>
          </div>
        </template>
        <!-- Column: Sender -->
        <template #cell(transfer_branch_sender)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.sender.branch_name }}</span>
          </div>
        </template>
        <!-- Column: Receiver -->
        <template #cell(transfer_branch_receiver)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.receiver.branch_name }}</span>
          </div>
        </template>
        <!-- Column: Notes -->
        <template #cell(transfer_notes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.transfer_notes? data.item.transfer_notes : '-' }}</span>
          </div>
        </template>
        <template #cell(transfer_created_time)="data">
            {{ dateSimple(data.item.transfer_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='laboratory-manager' &&  data.item.transfer_confirmed_by_lab_manager_receiver==NULL && data.item.transfer_branch_receiver == userBranchId"
@click="confirmTransferByLabManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='laboratory-manager' && data.item.transfer_confirmed_by_fa_coal_manager_sender == true &&  data.item.transfer_confirmed_by_lab_manager_sender==NULL && data.item.sender.branch_id == userBranchId"
@click="confirmTransferByLabManagerSender( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='warehouse-cabang' && data.item.transfer_confirmed_by_lab_manager_sender==true &&  data.item.transfer_sent_by_sender==NULL && data.item.sender.branch_id == userBranchId"
@click="confirmTransferByWarehouseSender( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='warehouse-cabang' &&  data.item.transfer_sent_by_sender==true && data.item.transfer_received_by_receiver == NULL && data.item.receiver.branch_id == userBranchId"
@click="confirmTransferByWarehouseReceiver( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='regional-manager' && data.item.transfer_confirmed_by_lab_manager_receiver==true && data.item.transfer_confirmed_by_regional_manager_sender==NULL"
@click="confirmTransferByRegionalManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='division-head' && data.item.transfer_confirmed_by_regional_manager_sender==true && data.item.transfer_confirmed_by_division_head_sender==NULL"
@click="confirmTransferByDivisionHead( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='fa-coal-manager' && data.item.transfer_confirmed_by_division_head_sender==true && data.item.transfer_confirmed_by_fa_coal_manager_sender==NULL"
@click="confirmTransferByFACoalManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="data.item.transfer_rejected_by_rejector==null && data.item.transfer_received_by_receiver==null"
@click="rejectTransfer( data.item )"
>
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Reject</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
v-if="hasPermission('transfers_receive')==true && data.item.transfer_confirmed_by_lab_manager_sender==true && data.item.transfer_confirmed_by_lab_manager_receiver==true && data.item.transfer_received_by_receiver==NULL"
@click="receiveTransfer(  data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item> -->
          <!-- <b-dropdown-item
v-if="hasPermission('transfers_decline')==true && data.item.transfer_confirmed_by_lab_manager_receiver==null"
@click="declineTransfer( data.item.purchase_order_id )"
>
            <feather-icon icon="XIcon" />
            <span class="align-middle ml-50">Decline</span>
          </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransferes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
      <b-tab title="Needs Approval">
        <div class="m-2">

<!-- Table Top -->
<b-row>

  <!-- Per Page -->
  <b-col
    cols="12"
    md="6"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
  >
    <label>Show</label>
    <v-select
      v-model="perPagNAe"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
    />
    <label>entries</label>
  </b-col>
  <b-col
    cols="12"
    md="6"
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-form-input
        v-model="searchQueryNA"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
      <b-button
        v-if="createShow"
        variant="primary"
        @click="addNew()"
      >
        <span class="text-nowrap">Add Transfer</span>
      </b-button>
    </div>
  </b-col>
</b-row>
</div>
      <b-table
        ref="refTransferListTableNA"
        class="position-relative"
        :items="fetchTransfersNA"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortByNA"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescNA"
      >
        <!-- Column: Number -->
        <template #cell(transfer_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.transfer_number }}</span>
          </div>
        </template>
        <!-- Column: Sender -->
        <template #cell(transfer_branch_sender)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.sender.branch_name }}</span>
          </div>
        </template>
        <!-- Column: Receiver -->
        <template #cell(transfer_branch_receiver)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.receiver.branch_name }}</span>
          </div>
        </template>
        <!-- Column: Notes -->
        <template #cell(transfer_notes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.transfer_notes? data.item.transfer_notes : '-' }}</span>
          </div>
        </template>
        <template #cell(transfer_created_time)="data">
            {{ dateSimple(data.item.transfer_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='laboratory-manager' &&  data.item.transfer_confirmed_by_lab_manager_receiver==NULL && data.item.transfer_branch_receiver == userBranchId"
@click="confirmTransferByLabManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='laboratory-manager' && data.item.transfer_confirmed_by_fa_coal_manager_sender == true &&  data.item.transfer_confirmed_by_lab_manager_sender==NULL && data.item.sender.branch_id == userBranchId"
@click="confirmTransferByLabManagerSender( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='warehouse-cabang' && data.item.transfer_confirmed_by_lab_manager_sender==true &&  data.item.transfer_sent_by_sender==NULL && data.item.sender.branch_id == userBranchId"
@click="confirmTransferByWarehouseSender( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='warehouse-cabang' &&  data.item.transfer_sent_by_sender==true && data.item.transfer_received_by_receiver == NULL && data.item.receiver.branch_id == userBranchId"
@click="confirmTransferByWarehouseReceiver( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='regional-manager' && data.item.transfer_confirmed_by_lab_manager_receiver==true && data.item.transfer_confirmed_by_regional_manager_sender==NULL"
@click="confirmTransferByRegionalManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='division-head' && data.item.transfer_confirmed_by_regional_manager_sender==true && data.item.transfer_confirmed_by_division_head_sender==NULL"
@click="confirmTransferByDivisionHead( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='fa-coal-manager' && data.item.transfer_confirmed_by_division_head_sender==true && data.item.transfer_confirmed_by_fa_coal_manager_sender==NULL"
@click="confirmTransferByFACoalManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="data.item.transfer_rejected_by_rejector==null && data.item.transfer_received_by_receiver==null"
@click="rejectTransfer( data.item )"
>
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Reject</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
v-if="hasPermission('transfers_receive')==true && data.item.transfer_confirmed_by_lab_manager_sender==true && data.item.transfer_confirmed_by_lab_manager_receiver==true && data.item.transfer_received_by_receiver==NULL"
@click="receiveTransfer(  data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item> -->
          <!-- <b-dropdown-item
v-if="hasPermission('transfers_decline')==true && data.item.transfer_confirmed_by_lab_manager_receiver==null"
@click="declineTransfer( data.item.purchase_order_id )"
>
            <feather-icon icon="XIcon" />
            <span class="align-middle ml-50">Decline</span>
          </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMetaNA.from }} to {{ dataMetaNA.to }} of {{ dataMetaNA.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageNA"
              :total-rows="totalTransferNA"
              :per-page="perPageNA"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
      <b-tab title="Finished">
        <div class="m-2">

<!-- Table Top -->
<b-row>

  <!-- Per Page -->
  <b-col
    cols="12"
    md="6"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
  >
    <label>Show</label>
    <v-select
      v-model="perPageFinished"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
    />
    <label>entries</label>
  </b-col>
  <b-col
    cols="12"
    md="6"
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-form-input
        v-model="searchQueryFinished"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
      <b-button
        v-if="createShow"
        variant="primary"
        @click="addNew()"
      >
        <span class="text-nowrap">Add Transfer</span>
      </b-button>
    </div>
  </b-col>
</b-row>
</div>
      <b-table
        ref="refTransferListTableFinished"
        class="position-relative"
        :items="fetchTransfersFinished"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortByFinished"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescFinished"
      >
        <!-- Column: Number -->
        <template #cell(transfer_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.transfer_number }}</span>
          </div>
        </template>
        <!-- Column: Sender -->
        <template #cell(transfer_branch_sender)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.sender.branch_name }}</span>
          </div>
        </template>
        <!-- Column: Receiver -->
        <template #cell(transfer_branch_receiver)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.receiver.branch_name }}</span>
          </div>
        </template>
        <!-- Column: Notes -->
        <template #cell(transfer_notes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.transfer_notes? data.item.transfer_notes : '-' }}</span>
          </div>
        </template>
        <template #cell(transfer_created_time)="data">
            {{ dateSimple(data.item.transfer_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='laboratory-manager' &&  data.item.transfer_confirmed_by_lab_manager_receiver==NULL && data.item.transfer_branch_receiver == userBranchId"
@click="confirmTransferByLabManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='laboratory-manager' && data.item.transfer_confirmed_by_fa_coal_manager_sender == true &&  data.item.transfer_confirmed_by_lab_manager_sender==NULL && data.item.sender.branch_id == userBranchId"
@click="confirmTransferByLabManagerSender( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='warehouse-cabang' && data.item.transfer_confirmed_by_lab_manager_sender==true &&  data.item.transfer_sent_by_sender==NULL && data.item.sender.branch_id == userBranchId"
@click="confirmTransferByWarehouseSender( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='warehouse-cabang' &&  data.item.transfer_sent_by_sender==true && data.item.transfer_received_by_receiver == NULL && data.item.receiver.branch_id == userBranchId"
@click="confirmTransferByWarehouseReceiver( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='regional-manager' && data.item.transfer_confirmed_by_lab_manager_receiver==true && data.item.transfer_confirmed_by_regional_manager_sender==NULL"
@click="confirmTransferByRegionalManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='division-head' && data.item.transfer_confirmed_by_regional_manager_sender==true && data.item.transfer_confirmed_by_division_head_sender==NULL"
@click="confirmTransferByDivisionHead( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='fa-coal-manager' && data.item.transfer_confirmed_by_division_head_sender==true && data.item.transfer_confirmed_by_fa_coal_manager_sender==NULL"
@click="confirmTransferByFACoalManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="data.item.transfer_rejected_by_rejector==null && data.item.transfer_received_by_receiver==null"
@click="rejectTransfer( data.item )"
>
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Reject</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
v-if="hasPermission('transfers_receive')==true && data.item.transfer_confirmed_by_lab_manager_sender==true && data.item.transfer_confirmed_by_lab_manager_receiver==true && data.item.transfer_received_by_receiver==NULL"
@click="receiveTransfer(  data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item> -->
          <!-- <b-dropdown-item
v-if="hasPermission('transfers_decline')==true && data.item.transfer_confirmed_by_lab_manager_receiver==null"
@click="declineTransfer( data.item.purchase_order_id )"
>
            <feather-icon icon="XIcon" />
            <span class="align-middle ml-50">Decline</span>
          </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMetaFinished.from }} to {{ dataMetaFinished.to }} of {{ dataMetaFinished.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageFinished"
              :total-rows="totalTransfersFinished"
              :per-page="perPageFinished"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
      <b-tab title="Rejected">
        <div class="m-2">

<!-- Table Top -->
<b-row>

  <!-- Per Page -->
  <b-col
    cols="12"
    md="6"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
  >
    <label>Show</label>
    <v-select
      v-model="perPageRejected"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
    />
    <label>entries</label>
  </b-col>
  <b-col
    cols="12"
    md="6"
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-form-input
        v-model="searchQueryRejected"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
      <b-button
        v-if="createShow"
        variant="primary"
        @click="addNew()"
      >
        <span class="text-nowrap">Add Transfer</span>
      </b-button>
    </div>
  </b-col>
</b-row>
</div>
      <b-table
        ref="refTransferListTableRejected"
        class="position-relative"
        :items="fetchTransfersRejected"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortByRejected"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescRejected"
      >
        <!-- Column: Number -->
        <template #cell(transfer_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.transfer_number }}</span>
          </div>
        </template>
        <!-- Column: Sender -->
        <template #cell(transfer_branch_sender)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.sender.branch_name }}</span>
          </div>
        </template>
        <!-- Column: Receiver -->
        <template #cell(transfer_branch_receiver)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.receiver.branch_name }}</span>
          </div>
        </template>
        <!-- Column: Notes -->
        <template #cell(transfer_notes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.transfer_notes? data.item.transfer_notes : '-' }}</span>
          </div>
        </template>
        <template #cell(transfer_created_time)="data">
            {{ dateSimple(data.item.transfer_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='laboratory-manager' &&  data.item.transfer_confirmed_by_lab_manager_receiver==NULL && data.item.transfer_branch_receiver == userBranchId"
@click="confirmTransferByLabManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='laboratory-manager' && data.item.transfer_confirmed_by_fa_coal_manager_sender == true &&  data.item.transfer_confirmed_by_lab_manager_sender==NULL && data.item.sender.branch_id == userBranchId"
@click="confirmTransferByLabManagerSender( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='warehouse-cabang' && data.item.transfer_confirmed_by_lab_manager_sender==true &&  data.item.transfer_sent_by_sender==NULL && data.item.sender.branch_id == userBranchId"
@click="confirmTransferByWarehouseSender( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='warehouse-cabang' &&  data.item.transfer_sent_by_sender==true && data.item.transfer_received_by_receiver == NULL && data.item.receiver.branch_id == userBranchId"
@click="confirmTransferByWarehouseReceiver( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='regional-manager' && data.item.transfer_confirmed_by_lab_manager_receiver==true && data.item.transfer_confirmed_by_regional_manager_sender==NULL"
@click="confirmTransferByRegionalManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='division-head' && data.item.transfer_confirmed_by_regional_manager_sender==true && data.item.transfer_confirmed_by_division_head_sender==NULL"
@click="confirmTransferByDivisionHead( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('transfers_confirm')==true && userRole=='fa-coal-manager' && data.item.transfer_confirmed_by_division_head_sender==true && data.item.transfer_confirmed_by_fa_coal_manager_sender==NULL"
@click="confirmTransferByFACoalManager( data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="data.item.transfer_rejected_by_rejector==null && data.item.transfer_received_by_receiver==null"
@click="rejectTransfer( data.item )"
>
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Reject</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
v-if="hasPermission('transfers_receive')==true && data.item.transfer_confirmed_by_lab_manager_sender==true && data.item.transfer_confirmed_by_lab_manager_receiver==true && data.item.transfer_received_by_receiver==NULL"
@click="receiveTransfer(  data.item )"
>
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item> -->
          <!-- <b-dropdown-item
v-if="hasPermission('transfers_decline')==true && data.item.transfer_confirmed_by_lab_manager_receiver==null"
@click="declineTransfer( data.item.purchase_order_id )"
>
            <feather-icon icon="XIcon" />
            <span class="align-middle ml-50">Decline</span>
          </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMetaRejected.from }} to {{ dataMetaRejected.to }} of {{ dataMetaRejected.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageRejected"
              :total-rows="totalTransferRejected"
              :per-page="perPageRejected"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, VBModal, BListGroup, BListGroupItem, BTabs, BTab,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import TransferList from './List'

const userRole = ''

const messageBox = ''
const to = ''
const cc = ''
const reason = ''
const TransferId = ''
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
const modalReject = false
const chosenRejectId = ''
const userBranchId = getUserData().user_branch_id
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    // TransferesListFilters,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    vSelect,
  },
  data() {
    return {
      chosenRejectId,
      modalReject,
      userBranchId,
      items,
      statusItems,
      TransferId,
      userId,
      messagesList,
      createShow: true,
      excludeRoleToCreate: ['laboratory-manager'],
      userRole,
  }
},
  mounted() {
    this.userRole = this.$cookies.get('UserRole')
    console.log("USER ROLE", this.userRole)
    if (this.excludeRoleToCreate.includes(this.userRole)) {
      this.createShow = false
    }
    this.$root.$on('refreshTable', text => {
    console.log(text) // here you need to use the arrow function
    this.$refs.refTransferListTable.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-transfers'
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      })

    const isAddNewTransferSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchTransfersFinished,
      sortByFinished,
      isSortDirDescFinished,
      dataMetaFinished,
      currentPageFinished,
      totalTransfersFinished,
      perPageFinished,
      fetchTransfersNA,
      sortByNA,
      isSortDirDescNA,
      dataMetaNA,
      currentPageNA,
      totalTransferNA,
      perPageNA,
      fetchTransfersRejected,
      sortByRejected,
      isSortDirDescRejected,
      dataMetaRejected,
      currentPageRejected,
      totalTransferRejected,
      perPageRejected,
      fetchTransfers,
      tableColumns,
      perPage,
      currentPage,
      totalTransferes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTransferListTable,
      refetchData,

      // UI
      resolveTransferRoleVariant,
      resolveTransferRoleIcon,
      resolveTransferStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = TransferList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    return {
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewTransferSidebarActive,
      userRole,
      fetchTransfers,
      tableColumns,
      perPage,
      currentPage,
      totalTransferes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTransferListTable,
      refetchData,
      fetchTransfersFinished,
      sortByFinished,
      isSortDirDescFinished,
      dataMetaFinished,
      currentPageFinished,
      totalTransfersFinished,
      perPageFinished,
      fetchTransfersNA,
      sortByNA,
      isSortDirDescNA,
      dataMetaNA,
      currentPageNA,
      totalTransferNA,
      perPageNA,
      fetchTransfersRejected,
      sortByRejected,
      isSortDirDescRejected,
      dataMetaRejected,
      currentPageRejected,
      totalTransferRejected,
      perPageRejected,
      // Filter
      avatarText,

      // UI
      resolveTransferRoleVariant,
      resolveTransferRoleIcon,
      resolveTransferStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
    confirmReject() {
        const userToken = this.$cookies.get('userToken')
        const id = this.chosenRejectId
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_REJECT_TRANSFER}${id}`
        const body = {
        time: moment(),
        }
          axios
              .post(url,body, { headers })
              .then(response => {
                if (response.data.success === true) {
                  this.modalReject = false
                  this.$refs.refTransferListTable.refresh()
                  this.$refs.refTransferListTableNA.refresh()
                  this.$refs.refTransferListTableFinished.refresh()
                  this.$refs.refTransferListTableRejected.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been rejected!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      deleteTransfer(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
          axios
              .get(`${process.env.VUE_APP_API_DELETE_TRANSFER}${id}`, { headers })
              .then(response => {
                console.log(response)
                if (response.data.success === true) {
                  this.$refs.refTransferListTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editTransfer(data) {
      localStorage.setItem('TransferEdit', JSON.stringify(data))
          this.$router.push({ name: 'apps-transfers-edit' })
      },
      confirmTransferByLabManager(item) {
      localStorage.setItem('TransferDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-lab-manager' })
      },
      confirmTransferByLabManagerSender(item) {
      localStorage.setItem('TransferDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-lab-manager-sender' })
      },
      confirmTransferByWarehouseSender(item) {
      localStorage.setItem('TransferDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-warehouse-sender' })
      },
      confirmTransferByWarehouseReceiver(item) {
      localStorage.setItem('TransferDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-warehouse-receiver' })
      },
      confirmTransferByRegionalManager(item) {
      localStorage.setItem('TransferDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-regional-manager' })
      },
      confirmTransferByDivisionHead(item) {
      localStorage.setItem('TransferDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-division-head' })
      },
      confirmTransferByFACoalManager(item) {
      localStorage.setItem('TransferDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-fa-coal-manager' })
      },
      rejectTransfer(item) {
        this.chosenRejectId = item.transfer_id
        this.modalReject = true
      },
      declineTransfer(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const body = {
        time: moment(),
        }
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DECLINE_TRANSFER}/${id}`
        axios
          .post(url, body, { headers })
      .then(async response => {
        if (response.data.success === true) {
          this.$refs.refTransferListTable.refresh()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Transfer Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      },
    addNew() {
          this.$router.push({ name: 'apps-transfers-create' })
    },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    showDetail(item) {
      localStorage.setItem('TransferDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-details' })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get('userPermissions').split(',')
      return permissions.includes(permission) ? true : false
    },
    async receiveTransfer(item) {
      this.$cookies.set('TransferDetail', item)
      this.$router.push({ name: 'apps-transfers-receive' })
    },
    async sendMessage() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      const id = this.TransferId
      const Message = this.messageBox
      const body = {
        transfer_chat_transfer_id: id,
        transfer_chat_text: Message,
        time: moment(),
      }
    axios
      .post(`${process.env.VUE_APP_API_CREATE_MATERIAL_REQUEST_CHATS}`, body, { headers })
      .then(async response => {
        if (response.data.success === true) {
          this.messageBox = ''
    axios
      .get(`${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`, { headers })
      .then(response2 => {
        if (response2.data.success === true) {
          console.log('Fetching Data')
      if (response2.data.data) {
      messagesList.splice(0, messagesList.length)
        response2.data.data.map(value => {
          messagesList.push(value)
          return value.invoiceMessage
        })
      }
        }
        })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
